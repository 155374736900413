


























































import { Component, Vue } from 'vue-property-decorator'
import Content from '@/components/Content.vue'
import Header from '@/components/Header.vue'
import FaqItem from '@/components/FaqItem.vue'

@Component({
  components: {
    Header,
    Content,
    FaqItem
  }
})
export default class Faq extends Vue {
  faqs: any[] = [
    {
      slug: 'was-genau-ist-meinschwein',
      q: 'Was genau ist „meinSCHWEIN“ und wie funktioniert es?',
      a: 'Mit der Plattform „meinSCHWEIN“ wird die Möglichkeit geschaffen, dass jeder Kunde sein eigenes Schwein als Ferkel bei einem registrierten Betrieb kaufen und einstellen kann. Haltung, Fütterung und Pflege des Schweines können beeinflusst und auch jederzeit im Stall beobacht werden. Es handelt sich dabei um eine neue Form der Schweinemast, bei der Konsumenten sowie Landwirte eng miteinander zusammenarbeiten, um so das Vertrauen in die Landwirtschaft zu verbessern, aber auch das Tierwohl zu steigern. Bei der Verwertung hat der Kunde die Möglichkeit, sich sein Schwein als Ganzes oder seinen Ansprüchen gerecht zerlegt, verarbeitet und verpackt zu bekommen.'
    },
    {
      slug: 'wie-viele-meinschwein-betriebe-gibt-es',
      q: 'Wie viele „meinSCHWEIN“ Betriebe gibt es?',
      a: 'Unsere registrierten „meinSCHWEIN“ Betriebe werden stetig mehr. <a target="_blank" href="/betriebe">Wirf hier einen Blick auf alle aktuell registrierten Betriebe.</a>'
    },
    {
      slug: 'was-bedeutet-einstelltermin',
      q: 'Was bedeutet „Einstelltermin“?',
      a: 'Der Einstelltermin ist der Tag, an dem dein Schwein den Stall bezieht. Ab diesem Datum kannst du dein Schwein rund um die Ohr beobachten und Zusatzfutter bzw. Extras kaufen.'
    },
    {
      slug: 'was-bedeutet-abholtermin',
      q: 'Was bedeutet „Abholtermin“?',
      a: 'Der Abholtermin ist der Tag, an dem dein Schwein verfügbar bzw. abholbereit ist. Die Schlachtung erfolgt meist einige Tage davor.'
    },
    {
      slug: 'wie-lange-werden-schweine-eingestellt',
      q: 'Wie lange werden Schweine eingestellt?',
      a: 'Ein Einstellzeitraum beträgt grundsätzlich 4 ½ Monate. Die Einstell- und Abholtermine können um einige Tage variieren.'
    },
    {
      slug: 'kann-ich-mein-schwein-laenger-halten',
      q: 'Kann ich mein Schwein auch länger als 4 ½ Monate halten?',
      a: 'Aus organisatorischen Gründen ist es nicht möglich, Schweine länger als den vorgegebenen Zeitraum von 4 ½ Monaten zu halten.'
    },
    {
      slug: 'warum-sind-die-schweine-gefleckt',
      q: 'Warum sind die Schweine schwarz gefleckt?',
      a: 'Bei unseren Schweinen handelt es sich um eine Edelrasse, die in einem eigenen Betrieb gezüchtet wird.'
    },
    {
      slug: 'muss-man-die-fuetterung-ergaenzen',
      q: 'Muss man die Fütterung mit Zusatzfutter ergänzen?',
      a: 'Nein, das Ergänzen durch Zusatzfutter ist optional. Die Grundfütterung ist beim Schweinekauf inkludiert und wird deinem Schwein automatisch serviert.'
    },
    {
      slug: 'wie-viel-fleisch-bekomme-ich',
      q: 'Wie viel Fleisch erhält man pro Schwein?',
      a: 'Für ein Schwein bekommt man Fleisch und Wurstwaren die ausreichen, um eine durchschnittliche 4-köpfige Familie 6 Monate zu ernähren und füllt eine Tiefkühltruhe zur Hälfte.'
    },
    {
      slug: 'welche-zerlegevarianten-gibt-es',
      q: 'Welche Zerlegevarianten gibt es und wie viel kosten diese?',
      a: 'Grundsätzlich gibt es vier Zerlegevarianten: <ol><li>Im Ganzen (€ 60,00/Schwein): Du erhältst die Schweinehälften im Ganzen <img class="h-64" src="https://firebasestorage.googleapis.com/v0/b/agromedia-meinschwein.appspot.com/o/content%2Fcutting_whole.png?alt=media&token=8aa9ec72-01fa-4f4f-af1a-fa5831d4e295"/ ></li><li>Gastro (€ 120,00/Schwein): Du erhältst dein Schwein in einer für die Gastronomie üblichen Variante, d.h. die meisten Teile im Ganzen <img class="h-64" src="https://firebasestorage.googleapis.com/v0/b/agromedia-meinschwein.appspot.com/o/content%2Fcutting_gastronomy.png?alt=media&token=70cfccaa-25fc-43d6-8deb-b1601ac495d9"/ ></li><li>Fein (€ 150,00/Schwein): Du erhältst dein Schwein in einer für den Privatgebrauch üblichen Variante, d.h. die meisten Teile geschnitten <img class="h-64" src="https://firebasestorage.googleapis.com/v0/b/agromedia-meinschwein.appspot.com/o/content%2Fcutting_fine.png?alt=media&token=06423a8f-db16-4cac-9aa7-3cecadd5029a"/ ></li><li>Fein (vakuumverpackt) (€ 220,00/Schwein): Gleiche Zerlegung wie „Fein“, wobei die einzelnen Teile vakuumverpackt werden <img class="h-64" src="https://firebasestorage.googleapis.com/v0/b/agromedia-meinschwein.appspot.com/o/content%2Fcutting_fine_vacuum_packed.png?alt=media&token=1c4e2e1d-40b0-447d-8b23-8ae66d558f90"/ ></li></ol>'
    },
    {
      slug: 'welche-zustellmoeglichkeiten-gibt-es',
      q: 'Welche Zustellmöglichkeiten gibt es?',
      a: 'Du kannst das Fleisch nach der Schlachtung bei deinem gewählten meinSCHWEIN Betrieb selbst abholen oder zustellen lassen. Im Umkreis von 25km um den Betrieb beträgt der Preis für die Zustellung pauschal € 20,00, darüber hinaus € 1,20/km.'
    },
    {
      slug: 'wie-lange-ist-eingekuehltes-schweinefleisch-haltbar',
      q: 'Wie lange ist eingekühltes Schweinefleisch haltbar?',
      a: 'Schweinefleisch kann problemlos bis zu 12 Monate eingefroren werden. Ausgenommen ist faschiertes Fleisch, dieses liegt bei der halben Zeit.'
    },
    {
      slug: 'wie-lange-ist-vakuumverpacktes-schweinefleisch-haltbar',
      q: 'Wie lange ist vakuumverpacktes Schweinefleisch im Kühlschrank haltbar?',
      a: 'Vakuumverpacktes Schweinefleisch ist im Kühlschrank 20-28 Tage haltbar. Ausgenommen ist faschiertes Fleisch, dieses liegt bei der halben Zeit.'
    },
    {
      slug: 'verliert-das-fleisch-durch-einfrieren-qualitaet',
      q: 'Verliert das Fleisch durch das Einfrieren an Qualität?',
      a: 'Wenn das Fleisch richtig eingefroren wurde (mind. -18°C) und langsam aufgetaut wird (im Kühlschrank) dann verliert das Fleisch nicht an Qualität. Wer also gutes Fleisch bestellt, sollte keinerlei Bedenken haben, es einzufrieren bzw. gefroren geliefert zu bekommen. Der Genuss- und Nährwert leidet nicht.'
    },
    {
      slug: 'wie-kann-ich-meine-bestellung-bezahlen',
      q: 'Wie kann ich meine Bestellung bezahlen?',
      a: 'Du kannst bei uns mittels Kreditkarte, SEPA Lastschrift, SOFORT Überweisung, Apple Pay oder Google Pay bezahlen. Manche Zahlungsarten (z.B. SEPA Lastschrift) müssen erst von deiner Bank genehmigt werden, wodurch sich die Gutschrift deiner Produkte um eine Tage verzögern kann.'
    }
  ]
}
